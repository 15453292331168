.contact-page {
  padding: 110px 0px;

  .contact-form {

    .contact-desc-title {
      margin-bottom: 40px;
      padding-bottom: 40px;
      text-align: center;

      .heading-contact-title {
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 20px;
        position: relative;
      }
    }

    .map-wrapper iframe {
      width: 100%;
    }

    .heading-footer-col {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      padding-bottom: 20px;
      position: relative;

      @media(max-width: 992px){
        margin-top: 60px;
      }

      &:before {
        position: absolute;
        left: 0px;
        content: '';
        bottom: 0;
        height: 2px;
        width: 30px;
        background: #ff9c1b;
        margin-bottom: 0px;
      }
    }
  }
}
