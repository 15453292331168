@import '../../styles/variables';
.about-page {
  @media(max-width: 1024px) {
    .none-sm {
      display: none;
    }
  }
  .wrap-content-page {
    padding: 110px 0px;
    img {
      max-width: 100%;
    }
  }
  h4.title {
    font-weight: 600;
  }
  p {
    text-align: justify;
  }
  ul {
    padding: 0px 0px 0px 17px;
  }
  @media(max-width: 1024px) {
    .wrap-content-page {
      padding: 50px 0px;
    }
  }
}