.location {
  padding: 0;
  display: flex;
  align-items: center;
  padding: 80px 15px;

  .map-wrapper {
    position: relative;
    height: 600px;
    width: 70%;
    flex: 2;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .address-wrapper {
    flex: 1;
    padding-left: 25px;
    .address {
      text-align: center;
    }
  }

  @media(max-width: 768px) {
    flex-direction: column;

    .map-wrapper {
      width: 100%;
      margin-bottom: 50px;
    }
  }

}