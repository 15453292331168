.walk-through-container {
  padding-top: 80px;

  .react-photo-gallery--gallery{
    margin: 0 -15px;
  }

  .image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    img {
      width: calc((100% / 4) - 30px) !important;
      height: 150px !important;
      margin: 15px !important;
      transition: box-shadow .5s, border-radius .5s, transform .5s;
  
      &:hover {
        border-radius: 5px;
        box-shadow: 0px 0px 10px 3px #cecece;
        transform: scale(1.1)
      }
  
      @media(max-width: 992px) {
        width: calc((100% / 3) - 4px) !important;
      }
      @media(max-width: 768px) {
        width: calc((100% / 2) - 4px) !important;
      }
      @media(max-width: 580px) {
        width: 100%!important;
        margin: 5px 0 !important;
      }
  
      object-fit: cover;
    }
  }

  .youtube-link {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
    margin-top: 60px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 3px #cecece;

    iframe {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }
}