@import '../../styles//variables';

.has-mega-menu {
  .mega-menu {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 105%;
    right: 0;
    width: 100%;
    transition: all 0.3s;
    max-height: 0;

    .mega-menu-body {
      background-color: #f4f6f5;
      border-top: 15px solid transparent;
      border-radius: 5px;
    }
  }

  &:hover {
    > a {
      background-color: $colorSecondary;
      color: $colorWhite !important;
    }
    > .mega-menu {
      max-height: 1000px;
      overflow: initial;
      bottom: 0;
      opacity: 1;
    }
  } 
}
