@import '../../styles/_variables';

.header {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 99;
  margin-top: -40px;
  position: relative;

  .container {
    background-color: #f4f6f5;
    border-radius: 5px;
    position: relative;
  }

  a.logo {
    img {
      height: 48px;
    }
  }

  .on-mobile {
    display: none;
  }
  .nav-links{
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;

    > li {
      > a {
        text-align: center;
        text-transform: uppercase;
        color: $colorSecondary;
        display: block;
        min-width: 120px;
        padding: 0 15px;
        font-weight: 600;
        font-size: 13px;
        height: 70px;
        line-height: 70px;
        transition: all .2s;

        &:hover {
          background: $colorSecondary;
          color: $colorWhite;
        }
      }
    }

  }


  @media(max-width: 1024px) {
    margin: 0;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 99999;
    top: 0;
    left: 0;

    .container {
      border-radius: 0;
      padding: 15px; 
      max-width: 100%;
      margin: 0;
    }

    a.logo {
      img {
        height: 40px;
      }
    }

    .on-desktop {
      display: none;
    }
    .on-mobile {
      display: block;
    }

    .on-mobile {
      .nav-links {
        width: 100%;
        max-height: 0vh;
        min-height: 0vh;
        overflow: hidden;
        opacity: 0;
        position: fixed;
        left: 0;
        top: 70px;
        background: #013350eb;
        transition: 0.2s;
        flex-direction: column;

        > li {
          > a {
            text-transform: uppercase;
            color: #ffffff;
            display: block;
            min-width: 120px;
            padding: 0 15px;
            font-weight: 600;
            font-size: 13px;
            height: 70px;
            line-height: 70px;
            transition: all .2s;
            border-bottom: 1px solid #5b8da8;
            background: #013350;
            text-align: left;
            margin-bottom: 0px;

            &:hover {
              background: $colorSecondary;
              color: $colorWhite;
            }
          }

          .mega-menu {
            display: none;
          }
        }

        &.open {
          opacity: 1;
          max-height: 100vh;
          min-height: 100vh;
          overflow: auto;
        }
      }
      .hamburger {
        background: transparent;
        border: none;
        height: 50px;
        position: absolute;
        right: 15px;
        top: 8px;
        display: block;

        span {
          display: block;
          margin: 12px 0;
          width: 40px;
          height: 2px;
          background: $colorSecondary; 
        }
        
      }
    }
  }



}
