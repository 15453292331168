.general-form-field {
  display: inline-block;
  width: 100%;
  input {
    &[type="text"], &[type="email"], &[type="number"] {
      background: #fff none repeat scroll 0 0;
      border: 1px solid #f0f0f0;
      margin-bottom: 20px;
      padding: 14px 12px;
      width: 100%;
      color: #999999;
    }

    &.theme-btn[type="submit"] {
      border: none;
      background-color: #013350;
      cursor: pointer;
      margin: 20px 0px;
      height: 50px;
      min-width: 200px;

      &:hover {
        background-color: #e67f10;
      }
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  textarea {
    border: 1px solid #f0f0f0;
    width: 100%;
    height: 180px;
    padding: 14px 12px;
    color: #999999;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}