@import '../../styles/variables';
.about-us {
  padding: 110px 0px;
  overflow: hidden;
  
  .section-title {
    width: 30%;
    float: left;
    margin-bottom: 0px;
    h2 {
      position: relative;
      padding-bottom: 25px;
      margin-bottom: 25px;
      font-weight: 700;
      &:before {
        position: absolute;
        content: '';
        left: 50%;
        bottom: 0;
        height: 2px;
        width: 40px;
        margin-left: -20px;
        background: $colorPrimary;
        margin-bottom: 0px;
      }
    }
  }

  .si-about {
    font-size: 16px;
    margin: 0 auto;
    width: 70%;
    float: left;
    text-align: left;
    border-left: 1px solid #bfbfbf;
    padding-left: 40px;
    @media(max-width: 768px) {
      border: none;
      max-width: calc(100% - 30px);
      float: none;
      padding: 0 15px;
      text-align: center;
    }
  }
}
