@import '../../styles/variables';
.get-in-touch {
  background: $colorDefault;
  border-top: 5px solid $colorBorder;

  @media(max-width: 1024px) {
    display: none;
  }

  .col-md-12 {
    min-height: 0px;
  }

  .wrap-git {
    // overflow: hidden;
    // width: 100%;
    // position: absolute;
    // z-index: 2;
    // background: #ffffff;
    // -webkit-border-radius: 5px;
    // -moz-border-radius: 5px;
    // border-radius: 5px;
    // margin-top: -75px;
  }

  .customsol {
    position: relative;
    padding: 30px 0px;

    .customsol-content {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;

      .git-left {
        flex: 3;

        .git-title {
          font-size: 1.5rem;
          font-family: 'Raleway', sans-serif;
          margin-bottom: 10px;
          display: block;
        }

        p {
          margin-bottom: 0;
        }
      }
      .git-right {
        flex: 1;
        padding-left: 40px;

        .btn-git {
          text-align: center;
          display: block;
          background: #ffffff;
          padding: 10px 20px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          font-weight: 600;
          color: $colorSecondary;
          border: 1px solid $colorSecondary;
          &:hover {
            background: $colorSecondary;
            color: #ffffff;
          }
        }
      }
    }
  }
}