@import '../../../styles/variables';
.bg-amenities {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  @include overlayWhiteSolid();
}
.amenities {
  padding: 50px 15px;
  @media(min-width: 768px) {
    padding: 100px;
  }
  position: relative;
  z-index: 1;

  ul.amenity-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li.amenity-item {
      border-radius: 5px;
      background-color: rgb(255, 255, 255);
      padding: 20px;
      min-width: 220px;
      max-width: 220px;
      color: #013350;
      text-align: center;
      margin: 15px;
      -webkit-align-items: center;
      align-items: center;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 16px;
      transition: box-shadow .5s;

      img {
        transition: transform .5s;
        width: 30px;
        display: block;
        margin: 10px auto 15px auto;
      }

      &:hover {
        box-shadow: 0px 0px 10px 3px #dee2e6;

        img {
          transform: scale(1.5);
        }
      }

    }
  }
}