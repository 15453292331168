.property-details-section {
  border-top: 1px solid #e9e9e9;
  position: relative;

  .sticky-nav {
    opacity: 0;
    position: fixed;
    display: flex;
    z-index: 1;
    justify-content: space-around;
    list-style-type: none;
    padding: 0;
    width: 100%;
    top: -10%;
    z-index: 999;
    transition: all .5s linear;

    &.active {
      top: 0;
      opacity: 1
    }

    li {
      flex-grow: 1;
      border-left: 2px solid #ddd;

      &:first-child {
        border: none;
      }

      a {
        transition: all .5s;
        padding: 15px;
        background-color: rgba(3, 45, 72, 0.84);
        color: #ffffff;
        text-align: center;
        display: block;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        border-bottom: 2px solid #fff;

      }
      &.active a {
        background: #e67f10 !important;
      }
    }

    &:hover {
      li {
        a {
          background-color: rgb(3, 45, 72);
        }
      }
    }
  }

}