@import '../../../styles/variables';


  .btn-enquiry {
    padding: 15px 40px;
    border-radius: 100px;
    font-weight: 600;
    margin: 35px 20px 0 0;
    color: $colorSecondary;
    border: 1px solid $colorPrimary;
    background-color: $colorPrimary;
    cursor: pointer;
    color: #ffffff;

    &:hover {
      border-color: $colorSecondary;
      background: $colorSecondary;
      color: #ffffff;
    }
  }
