.floor-node {
  position: relative;
  margin-bottom: 30px;
  display: block;
  background-color: #003352;
  cursor: pointer;
  box-shadow: 0px 0px 10px 3px #e9e9e9;
  border-radius: 5px;

  img {
    border-radius: 5px;
    width: 160px;
    height: 130px;
    object-fit: cover;
    display: block;
    margin: auto;

    @media (max-width: 768px) {
      height: 200px;
      width: 100%;
    }
  }

  .react-photo-gallery--gallery {
    overflow: hidden;
    img {
      display: none;
      margin: auto !important;

      &:first-child {
        display: block;
      }
    }
  }

  .quick-details {

    @media(min-width:769px)  {
      margin-left: -30px;
    }

    ul.list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li.item {
        font-weight: 600;
        padding: 6px 20px;
        color: white !important;
        line-height: 20px;

        &.bhk {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
          background: rgb(230, 127, 16);
          padding: 15px;
          display: inline-block;
          width: calc(100% - 15px);
          margin-top: -15px;
          border-radius: 5px;
        }

        span {
          display: block;
          font-weight: 600;
          font-size: 10px;
        }
      }
    }
    @media (max-width: 768px) {
      padding: 10px;

      ul.list {
        li.item {
          font-weight: 600;
          padding: 6px 20px;
          color: white !important;
          line-height: 20px;
  
          &.bhk {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;
            background: rgb(230, 127, 16);
            padding: 15px;
            display: block;
            width: 100%;
            margin-top: -40px;
          }
  
          span {
            display: block;
            font-weight: 600;
            font-size: 10px;
          }
        }
      }
    }
  }
}