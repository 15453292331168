@import '../../styles/variables';
section.footer {
  padding: 185px 0px 110px;
  background: $colorSecondary;
  color: #ffffff;
  position: relative;
  line-height: normal;
  a {
    color: #ffffff;
  }
  .ct-footer {
    .heading-footer-col {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      padding-bottom: 20px;
      position: relative;
      &:before {
        position: absolute;
        left: 0px;
        content: '';
        bottom: 0;
        height: 2px;
        width: 30px;
        background: $colorPrimary;
        margin-bottom: 0px;
      }
    }
    .content-footer-col {
      .footer-item-contact {
        padding-left: 25px;
        position: relative;
        margin-bottom: 10px;
        .fic-icon {
          position: absolute;
          left: 0px;
          top: 0px;
          .fa {
            color: $colorPrimary;
          }
        }
      }
    }
    .logo img {
      width: 230px;
      margin-bottom: 20px;
      border-radius: 5px;
      padding: 20px;
      background: #f4f4f4;
    }
  }
  ul {
    padding: 0px 0px;
    margin: 0px 0px;
    list-style: none;
  }
  @media(max-width:1024px) {
    padding-top: 100px;
    .heading-footer-col {
      margin-top: 40px;
    }
  }
}

.wrap-circle-sosmed {
  display: table;
  width: 32px;
  height: 32px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 1px solid $colorPrimary;
  float: left;
  margin-right: 10px;
  text-align: center;
  a {
    display: table-cell;
    vertical-align: middle;
  }
}

footer {
  text-align: center;
  padding: 10px 0px;
  font-size: 14px;
}