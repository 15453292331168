.font-theme-color {
  color: $colorPrimary;
}

.theme-btn {
  background-color: $colorDarkText;
  color: #fff;
  font-weight: 600;
  padding: .375rem .75rem;
  line-height: 1.5;
  border-radius: .25rem;
  &.light {
    background-color: $colorPrimary;
    color: $colorDarkText;
    &:hover {
      background-color: $colorDarkText;
      color: #ffffff;
    }
  }
  &:hover {
    background-color: #ff9c1b;
  }
}

.theme-transition {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.main-wrapper {
  @media (min-width: 1025px) {
    margin-top: -110px;
  }
}