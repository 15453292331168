@import '../../styles/_variables';

.carousel {

  @media( max-width: 992px ) {
    margin-top: 0;
  }

  @include overlayBlack();

  .box-gallery {
    z-index: -1;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh !important;
      width: 100vw !important;
      object-fit: cover;
    }
  }


  img.static {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .table-wrapper {
    display: table;
    height: 100vh;
    width: 100%;
    position: relative;
    z-index: 1;

    .table-cell {
      vertical-align: middle;
      display: table-cell;
      text-align: center;

      h2 {
        color: $colorDefault;
        font-size: 58px;
        font-weight: 300;
        font-family: $fontPrimary;
        margin-top: 35px;
      }

      p {
        font-size: 20px;
        margin: 0;
        color: $colorDefault;
        font-weight: 500;
        text-shadow: 0.5px 0.5px 1px $colorDefault;
      }

      span {
        font-size: 68px;
        color: $colorDefault;
        top: 38px;
        position: relative;
        font-weight: 100;
        cursor: pointer;
      }
    }
    @media(max-width: 1024px) {
      height: 40vh;

      .table-cell {
        h2 {
          font-size: 38px;
          margin-top: 35px;
        }
        p {
          font-size: 20px;
        }  
        span {
          display: none;
        }
      }

    }
  }
}
