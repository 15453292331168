.Specifications {
  padding-top: 80px;
  padding-bottom: 80px;
  box-shadow: 0px 0px 8px 8px #f1f0f0;
  margin: 50px auto 30px auto;

  .nav-pills {
    align-items: stretch;
    justify-content: center;
  }

  div.content-wrapper {
    padding: 15px 0
  }
  div.content-wrapper *:last-child {
    margin-bottom: 0
  }

  li, p {
    margin-bottom: 15px;
  }

  .nav-item {
    display: flex;
    width: 220px;
    margin: 10px;

    .nav-link{
      font-weight: 600;
      color: #003352;
      border-bottom: 5px solid #013350;
      background: transparent;
      width: 100%;
      display: block;
      border-radius: 0;
      background-color: transparent;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      
      span {
        background-image: url('./012-general-info.svg');
        width: 30px;
        height: 30px;
        background-size: 100% 100%;
        margin-right: 15px;

        &.internal-wall-finishes {
          background-image: url('./003-indoor-walls.svg');
        }
        &.flooring {
          background-image: url('./001-indoor-flooring.svg');
        }
        &.ceilings {
          background-image: url('./010-ceiling.svg');
        }
        &.external-wall-finishes {
          background-image: url('./002-outdoor-walls.svg');
        }
        &.external-floor-finishes {
          background-image: url('./011-outdoor-flooring.svg');
        }
        
      }
      
      &.active {
        background-color: #fafafa;
        border-color: #e67f10;
      }
    }

  }


  .tab-content {
    border: 1px solid #e6e6e6;
    padding: 15px;
    margin-top: 23px;
    border-radius: 5px;
  }
}
