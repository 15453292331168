@import '../../../styles/_variables';

.slider-banner {
  overflow: hidden;
  @include overlayBlackHorizontal();
  position: relative;
  max-height: 490px;

  img {
    min-height: 500px;
    max-height: 500px;
  }

  .rera-number-wrapper {
    margin-top: 8px;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    display: inline-block;
    background-color: rgba(230, 126, 18, 0.9);
  }

  .intro {
    display: flex;
    align-items: center;
    height: 490px;
    position: relative;
    z-index: 1;
    margin-top: -520px;
    
    h1, h2 {
      margin: 70px 0 0 0;
      color: $colorDefault;
      font-size: 58px;
      font-weight: 300;
    }
  }

  @media (max-width: 580px) {
    max-height: 300px;
  }
}


