@import '../../../styles/variables';
.bg-near-by-places {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  @include overlayWhiteSolid();
}
.near-by-places {
  padding: 60px 0;
  position: relative;
  z-index: 1;

  ul.place-list {
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;

    .place-item {
      border-radius: 5px;
      padding: 20px;
      width: 100%;
      height: 280px;
      text-align: center;
      margin: 15px 0;
      -webkit-align-items: center;
      align-items: center;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 16px;
      transition: box-shadow .5s;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: stretch;

      img {
        transition: transform .5s;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
      }

      .content {
        border-radius: 5px;
        opacity: 0;
        text-align: center;
        position: relative;
        z-index: 1;
        padding: 20px;
        color: $colorSecondary;
        background-color: rgb(255, 255, 255);
        transition: all .5s;
        display: flex;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        align-items: stretch;

        h2 {
          font-size: 20px;
          margin-bottom: 10px;
        }
        p {
          margin: 2px;
        }
      }

      &:hover {
        transition: all .5s;
        box-shadow: 0px 0px 10px 3px #dee2e6;

        img {
          transform: scale(1.5);
        }

        .content {
          opacity: 1;
        }
      }

    }
  }
}