@import '../../styles/_variables';
.services {
  border-top: 0px solid #e6e6e6;
  position: relative;
  width: 100%;
  background: $colorSecondary;
  .no-padding {
    padding: 0;
  }

  .cm-left {
    width: 40%;
    float: left;
    height: 100%;
    padding: 0px;
    position: relative;
    @media(max-width: 1200px) {
      display: none;
    }
    .cm-left-content-layer {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: relative;
      padding: 160px 130px;
      color: #ffffff;
      .cm-left-content {
        position: relative;
        z-index: 2;
      }
      .section-title {
        color: #ffffff;
        background: $colorPrimary;
        padding: 50px;
        h2 {
          font-family: $fontSecondary;
          font-weight: 400;
          &:before {
            background: #ffffff;
          }
        }
      }
    }
    .bg-cm {
      height: 100%;
      background-position: center center;
      position: absolute;
      width: 100%;
      top: 0px;
      left: 0px;
      background-image: url('../../assets/images/bg-image2.jpg');
    }
  }

  .cm-right {
    width: 60%;
    float: left;
    padding: 110px 100px;
    min-height: 600px;
    background: $colorSecondary;
    color: #ffffff;

    @media(max-width: 1200px) {
      width: 100%;
      float: none;
      padding: 100px 20px;
    }

    .section-title h1::before {
      background: #ffffff;
    }
    .why-list {
      float: left;
      width: 100%;

      .wl-box {
        width: 50%;
        position: relative;
        float: left;
        margin-top: 30px;

        @media(max-width: 800px) {
          width: 100%;
          float: none;
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 80px;
          }
        }

        .wl-box-left {
          font-size: 50px;
          width: 50px;
          position: absolute;
          left: 0px;
          top: 0px;
          color: $colorPrimary;
        }
        .wl-box-right {
          padding-left: 80px;
          text-align: left;
          padding-right: 30px;
          h5 {
            font-family: $fontPrimary;
          }
        }
      }
    }
  }
}