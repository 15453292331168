@import '../../styles/variables';

.property-list-container {
  padding: 130px 0;

  .project-status-btn {
    max-width: 1024px;
    margin: auto;

    ul {
      flex-wrap: wrap;
      display: flex;
      list-style-type: none;
      margin: 0 0 40px 0;
      padding: 0;
      justify-content: center;

      li {
        flex-grow: 1;
        margin: 5px;

        button {
          width: 100%;
          background-color: $colorSecondary;
          color: $colorWhite;
          padding: 15px;
          display: inline-block;
          border: none;
          text-align: center;
          border-radius: 5px;
          outline: none !important;
          transition: background 0.5s;

          &.active {
            background-color: $colorPrimary;
          }
        }
      }
    }

    @media (max-width: 768px) {
      li {
        min-width: calc(50% - 10px);
      }
    }
  }
  .row.portfolio {
    justify-content: center;

    .node-wrapper {
      width: 430px;
      padding: 0 15px 15px; 
    }
  }


  &.mega-menu-content {
    padding: 15px 0; 

    .portfolio-gallery {
      .row:first-child {
        display: none;
      }
    }
    .project-status-btn {
      min-width: 240px !important;
      margin: 0 20px 0 0 !important;

      ul {
        flex-direction: column;
        margin: 0 0 15px 0;
        border-radius: 5px;
        overflow: hidden;

        li {
          button {
            text-transform: uppercase;
            border-radius: 0;
            margin: 0 0 2px 0;
            width: 100%;
            padding: 15px 15px;
            font-weight: 600;
            font-size: 13px;
            letter-spacing: 1px;
            text-align: left;

          }
          &:last-child button {
            margin: 0;
          }
        }
      }
    }
    
    .row.portfolio {
      max-height: 420px;
      overflow: auto;
      justify-content: flex-start;

      .node-wrapper.in-menu {
        margin: 0;
        display: inline-block;
      }
    }
  }

}