body, html {
  height: 100%;
  // scroll-behavior: smooth;
  // 
}
body {
  overflow-x: hidden;
  max-width: 100vw;
  padding: 80px 0 0 0;
  margin: 0;
  font-family: $fontSecondary;
  min-height: 100%;
  font-size: 14px;
  line-height: 1.65em;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: $colorText;
  background-color: $colorWhite;
  @media(max-width: 1024px) {
    padding-top: 70px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  font-family: $fontPrimary;
}

h1 {
  // font-size: 58px;
}

h2 {
  font-size: 35px;
}

a {
  background-color: transparent;
  color: $colorPrimary;
  text-decoration: none;
  &:hover, &:focus, &:active {
    text-decoration: none;
    color: #090909;
  }
}

strong {
  font-weight: 700;
}

.section-title {
  text-align: center;
  margin-bottom: 50px;
  h1, h2 {
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px;
    &:before {
      position: absolute;
      content: '';
      left: 50%;
      bottom: 0;
      height: 2px;
      width: 40px;
      margin-left: -20px;
      background: $colorPrimary;
      margin-bottom: 0px;
    }
  }
}

.badge-primary {
  background-color: $colorPrimary;
}

.badge-secondary {
  background-color: $colorSecondary;
}

#notification-wrapper{
  z-index: 9999;
  position: relative;

  .toast-notification {
    span {
      font-weight: 600;
      font-size: 12px
    }
  }
}

// ::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
// }

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// }

// ::-webkit-scrollbar-thumb {
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
// }