@import '../../../styles/variables';
.overview {
  padding: 80px 0px;

  @media(max-width: 769px){
    .col-md-12.d-flex.align-items-center {
      display: block !important;
    }
  }

  .section-title {
    width: 100%;

    @media(min-width: 769px){
      width: 30%;
      float: left;
      margin-bottom: 0px;
    }

    h2 {
      position: relative;
      padding-bottom: 25px;
      margin-bottom: 25px;
      font-weight: 400;

      @media(min-width: 769px){
        &:before {
          position: absolute;
          content: '';
          left: 50%;
          bottom: 0;
          height: 2px;
          width: 40px;
          margin-left: -20px;
          background: $colorPrimary;
          margin-bottom: 0px;
        }
      }
    }
  }

  .description {
    margin: 0 auto;

    @media(min-width: 769px){
      width: 70%;
      float: left;
      text-align: left;
      border-left: 1px solid #e6e6e6;
      padding-left: 40px;
    }

    @media(max-width: 768px){
      .text-left-sm {
        padding: 0 15px;
        text-align: left;
      }
    }

    .builder {
      text-align: left;
      margin-top: 12px;
      display: block;

      @media(max-width: 769px){
        padding: 0 15px;
      }

      h4 {
        margin-top: 35px;
        margin-bottom: 15px;
      }
      p {
        margin: 5px 0;
      }
    }

    .btn-download {
      background: $colorWhite;
      padding: 15px 40px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      border-radius: 100px;
      font-weight: 600;
      margin: 35px 20px 0 0;
      color: $colorSecondary;
      border: 1px solid $colorSecondary;
      cursor: pointer;

      &:hover {
        background: $colorSecondary;
        color: #ffffff;
      }
    }
    @media(max-width: 680px) {
      margin: 15px;
    }
  }

  .bank-node-wrapper {
    margin-top: 40px;

    .wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 15px -15px 0 -15px;
      
      .node {
        max-width: 200px;
        max-height: 170px;
        padding: 20px;
        margin: 15px;
        background-color: $colorWhite;
        color: $colorPrimary;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 3px #e9e9e9;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          object-fit: cover;
          display: block;
        }  
      }
      @media(max-width: 680px) {
        margin-bottom: 40px;
        
        .node {
          max-width: 100%;
          max-height: 100%;
          flex-grow: 1;
        }
      }
    }
  }

}