@mixin transition($time) {
  -webkit-transition: all $time ease-in-out 0s;
  -moz-transition: all $time ease-in-out 0s;
  transition: all $time ease-in-out 0s;
}

.team-section {
  padding: 0;
  padding-bottom: 110px;
  position: relative;
}

.team-section {
  .profile {
    margin-top: 25px;
    h1 {
      font-weight: normal;
      font-size: 20px;
      margin: 10px 0 0 0;
    }
    h2 {
      font-size: 14px;
      font-weight: lighter;
      margin-top: 5px;
    }
    .img-box {
      opacity: 1;
      display: block;
      position: relative;
      &:after {
        content: "";
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.75);
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
  .img-box ul {
    position: absolute;
    z-index: 2;
    bottom: 50px;
    text-align: center;
    width: 100%;
    padding-left: 0px;
    height: 0px;
    margin: 0px;
    opacity: 0;
  }
  .profile .img-box:after {
    @include transition(0.5s);
  }
  .img-box {
    ul {
      @include transition(0.5s);
      li {
        @include transition(0.5s);
      }
      i {
        font-size: 20px;
      }
      li {
        width: 30px;
        height: 30px;
        text-align: center;
        border: 1px solid #ff9c1b;
        margin: 2px;
        padding: 2px;
        display: inline-block;
      }
    }
    a {
      color: #fff;
    }
    &:hover {
      &:after, ul {
        opacity: 1;
      }
    }
    ul a {
      @include transition(0.3s);
    }
    a:hover li {
      border-color: #fff;
      color: #ff9c1b;
    }
  }
  a {
    color: #ff9c1b;
  }
  img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }
}
