@import '../../styles/variables';

.testimonials-section {
  padding: 110px 0px 185px;
  background: $colorSecondary;
  color: #ffffff;
  position: relative;
  overflow: hidden;

  .testimonials-wrap {
    position: relative;
    z-index: 1;
    .item-testimonial {
      position: relative;
      width: 100%;
      height: 180px;
      .client-info {
        width: 120px;
        text-align: center;
        position: absolute;
        .client-img {
          height: 100px;
          width: 100px;
          border-radius: 100%;
          overflow: hidden;
          margin: 0 auto 10px;
          img {
            width: 100%;
          }
        }
        .rating {
          color: $colorPrimary;
        }
      }
      .client-content {
        padding: 0px 30px 0px 150px;
        p {
          font-style: italic;
        }
        h4 {
          color: $colorPrimary;
        }
        h5 {
          font-weight: 300;
          font-size: 16px;
        }
      }
    }
  }
  .bg-tst {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0px;
    left: 0px;
    .bg-color-tst {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      background: $colorSecondary;
      opacity: 0.9;
    }
    .bg-img-tst {
      background: url('../../assets/images/bg-image2.jpg');
      background-size: 100% auto;
      z-index: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
  }

  @media(max-width: 1024px) {
    padding: 100px 0;
    .slick-arrow  {
      display: none !important;
    }
    .testimonials-wrap {
      .item-testimonial {
        .client-info {
          width: 80px;
          .client-img {
            height: 70px;
            width: 70px;
            img {
              width: 100%;
            }
          }
        }
        .client-content {
          padding: 0px 0 0px 95px;
        }
      } 
    }
  }
  
}
