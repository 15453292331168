@import '../../styles/_variables';
@import '../../styles/_theme';

.project-node {
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
  display: block;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 3px #e9e9e9;
  
  img {
    width: 100%;
    height: 480px;
    object-fit: cover;
  }

  .quick-details {
    color: #f3f3f3;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transform: translateY(80px);
    background-color: rgba(8, 51, 79, 0.90);
    @extend .theme-transition;

    &:hover {
      transform: translateY(0);
      opacity: 1;
    }

    .details-header {
      text-align: center;
      color: #e67f0f;
      padding: 15px;
      border-bottom: 1px solid #fff;
      margin-bottom: 30px;

      h2 {
        text-align: center;
        font-size: 31px;
        color: #fff;
        font-weight: 100;
        margin-bottom: 20px;
      }

      p{
        &:last-child {
          margin-bottom: 10px;
        }
      }
    }
    @media(max-width: 1024px) {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .sizes-wrapper {
    h2 {
      font-size: 14px;
      margin: 10px 0;
      text-align: center;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      padding: 0;
      margin:0;
      justify-content: center;
      align-items: stretch;

      li {
        border: 1px solid #ddd;
        padding: 10px 12px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-grow: 1;

        span {
          font-weight: 600;
          font-size: 12px;
          display: block;
        }
      }
    }
  }

  .rera-number {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 23px 10px;
    background-color: rgba(255, 255, 255, 0.65);
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: $colorDarkText;
  }
}