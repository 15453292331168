@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,500,600,700);
body, html {
  height: 100%; }

body {
  overflow-x: hidden;
  max-width: 100vw;
  padding: 80px 0 0 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  min-height: 100%;
  font-size: 14px;
  line-height: 1.65em;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: #303030;
  background-color: #ffffff; }
  @media (max-width: 1024px) {
    body {
      padding-top: 70px; } }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  font-family: "Oswald", sans-serif; }

h2 {
  font-size: 35px; }

a {
  background-color: transparent;
  color: #e67f10;
  text-decoration: none; }
  a:hover, a:focus, a:active {
    text-decoration: none;
    color: #090909; }

strong {
  font-weight: 700; }

.section-title {
  text-align: center;
  margin-bottom: 50px; }
  .section-title h1, .section-title h2 {
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px; }
    .section-title h1:before, .section-title h2:before {
      position: absolute;
      content: '';
      left: 50%;
      bottom: 0;
      height: 2px;
      width: 40px;
      margin-left: -20px;
      background: #e67f10;
      margin-bottom: 0px; }

.badge-primary {
  background-color: #e67f10; }

.badge-secondary {
  background-color: #013350; }

#notification-wrapper {
  z-index: 9999;
  position: relative; }
  #notification-wrapper .toast-notification span {
    font-weight: 600;
    font-size: 12px; }

.font-theme-color {
  color: #e67f10; }

.theme-btn {
  background-color: #171717;
  color: #fff;
  font-weight: 600;
  padding: .375rem .75rem;
  line-height: 1.5;
  border-radius: .25rem; }
  .theme-btn.light {
    background-color: #e67f10;
    color: #171717; }
    .theme-btn.light:hover {
      background-color: #171717;
      color: #ffffff; }
  .theme-btn:hover {
    background-color: #ff9c1b; }

.theme-transition {
  transition: all 0.5s ease; }

@media (min-width: 1025px) {
  .main-wrapper {
    margin-top: -110px; } }

.font-theme-color {
  color: #e67f10; }

.theme-btn {
  background-color: #171717;
  color: #fff;
  font-weight: 600;
  padding: .375rem .75rem;
  line-height: 1.5;
  border-radius: .25rem; }
  .theme-btn.light {
    background-color: #e67f10;
    color: #171717; }
    .theme-btn.light:hover {
      background-color: #171717;
      color: #ffffff; }
  .theme-btn:hover {
    background-color: #ff9c1b; }

.theme-transition, .project-node .quick-details {
  transition: all 0.5s ease; }

@media (min-width: 1025px) {
  .main-wrapper {
    margin-top: -110px; } }

.project-node {
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
  display: block;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 3px #e9e9e9; }
  .project-node img {
    width: 100%;
    height: 480px;
    object-fit: cover; }
  .project-node .quick-details {
    color: #f3f3f3;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    -webkit-transform: translateY(80px);
            transform: translateY(80px);
    background-color: rgba(8, 51, 79, 0.9); }
    .project-node .quick-details:hover {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1; }
    .project-node .quick-details .details-header {
      text-align: center;
      color: #e67f0f;
      padding: 15px;
      border-bottom: 1px solid #fff;
      margin-bottom: 30px; }
      .project-node .quick-details .details-header h2 {
        text-align: center;
        font-size: 31px;
        color: #fff;
        font-weight: 100;
        margin-bottom: 20px; }
      .project-node .quick-details .details-header p:last-child {
        margin-bottom: 10px; }
    @media (max-width: 1024px) {
      .project-node .quick-details {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1; } }
  .project-node .sizes-wrapper h2 {
    font-size: 14px;
    margin: 10px 0;
    text-align: center; }
  .project-node .sizes-wrapper ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: stretch; }
    .project-node .sizes-wrapper ul li {
      border: 1px solid #ddd;
      padding: 10px 12px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-grow: 1; }
      .project-node .sizes-wrapper ul li span {
        font-weight: 600;
        font-size: 12px;
        display: block; }
  .project-node .rera-number {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 23px 10px;
    background-color: rgba(255, 255, 255, 0.65);
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #171717; }

.loading-wapper {
  width: 100px;
  background-image: url(/static/media/spinner.bc95f072.svg);
  background-size: 100%;
  margin: auto;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 120px; }

.property-list-container {
  padding: 130px 0; }
  .property-list-container .project-status-btn {
    max-width: 1024px;
    margin: auto; }
    .property-list-container .project-status-btn ul {
      flex-wrap: wrap;
      display: flex;
      list-style-type: none;
      margin: 0 0 40px 0;
      padding: 0;
      justify-content: center; }
      .property-list-container .project-status-btn ul li {
        flex-grow: 1;
        margin: 5px; }
        .property-list-container .project-status-btn ul li button {
          width: 100%;
          background-color: #013350;
          color: #ffffff;
          padding: 15px;
          display: inline-block;
          border: none;
          text-align: center;
          border-radius: 5px;
          outline: none !important;
          transition: background 0.5s; }
          .property-list-container .project-status-btn ul li button.active {
            background-color: #e67f10; }
    @media (max-width: 768px) {
      .property-list-container .project-status-btn li {
        min-width: calc(50% - 10px); } }
  .property-list-container .row.portfolio {
    justify-content: center; }
    .property-list-container .row.portfolio .node-wrapper {
      width: 430px;
      padding: 0 15px 15px; }
  .property-list-container.mega-menu-content {
    padding: 15px 0; }
    .property-list-container.mega-menu-content .portfolio-gallery .row:first-child {
      display: none; }
    .property-list-container.mega-menu-content .project-status-btn {
      min-width: 240px !important;
      margin: 0 20px 0 0 !important; }
      .property-list-container.mega-menu-content .project-status-btn ul {
        flex-direction: column;
        margin: 0 0 15px 0;
        border-radius: 5px;
        overflow: hidden; }
        .property-list-container.mega-menu-content .project-status-btn ul li button {
          text-transform: uppercase;
          border-radius: 0;
          margin: 0 0 2px 0;
          width: 100%;
          padding: 15px 15px;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 1px;
          text-align: left; }
        .property-list-container.mega-menu-content .project-status-btn ul li:last-child button {
          margin: 0; }
    .property-list-container.mega-menu-content .row.portfolio {
      max-height: 420px;
      overflow: auto;
      justify-content: flex-start; }
      .property-list-container.mega-menu-content .row.portfolio .node-wrapper.in-menu {
        margin: 0;
        display: inline-block; }

.has-mega-menu .mega-menu {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 105%;
  right: 0;
  width: 100%;
  transition: all 0.3s;
  max-height: 0; }
  .has-mega-menu .mega-menu .mega-menu-body {
    background-color: #f4f6f5;
    border-top: 15px solid transparent;
    border-radius: 5px; }

.has-mega-menu:hover > a {
  background-color: #013350;
  color: #ffffff !important; }

.has-mega-menu:hover > .mega-menu {
  max-height: 1000px;
  overflow: initial;
  bottom: 0;
  opacity: 1; }

.header {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 99;
  margin-top: -40px;
  position: relative; }
  .header .container {
    background-color: #f4f6f5;
    border-radius: 5px;
    position: relative; }
  .header a.logo img {
    height: 48px; }
  .header .on-mobile {
    display: none; }
  .header .nav-links {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0; }
    .header .nav-links > li > a {
      text-align: center;
      text-transform: uppercase;
      color: #013350;
      display: block;
      min-width: 120px;
      padding: 0 15px;
      font-weight: 600;
      font-size: 13px;
      height: 70px;
      line-height: 70px;
      transition: all .2s; }
      .header .nav-links > li > a:hover {
        background: #013350;
        color: #ffffff; }
  @media (max-width: 1024px) {
    .header {
      margin: 0;
      position: fixed;
      width: 100%;
      height: 70px;
      z-index: 99999;
      top: 0;
      left: 0; }
      .header .container {
        border-radius: 0;
        padding: 15px;
        max-width: 100%;
        margin: 0; }
      .header a.logo img {
        height: 40px; }
      .header .on-desktop {
        display: none; }
      .header .on-mobile {
        display: block; }
      .header .on-mobile .nav-links {
        width: 100%;
        max-height: 0vh;
        min-height: 0vh;
        overflow: hidden;
        opacity: 0;
        position: fixed;
        left: 0;
        top: 70px;
        background: #013350eb;
        transition: 0.2s;
        flex-direction: column; }
        .header .on-mobile .nav-links > li > a {
          text-transform: uppercase;
          color: #ffffff;
          display: block;
          min-width: 120px;
          padding: 0 15px;
          font-weight: 600;
          font-size: 13px;
          height: 70px;
          line-height: 70px;
          transition: all .2s;
          border-bottom: 1px solid #5b8da8;
          background: #013350;
          text-align: left;
          margin-bottom: 0px; }
          .header .on-mobile .nav-links > li > a:hover {
            background: #013350;
            color: #ffffff; }
        .header .on-mobile .nav-links > li .mega-menu {
          display: none; }
        .header .on-mobile .nav-links.open {
          opacity: 1;
          max-height: 100vh;
          min-height: 100vh;
          overflow: auto; }
      .header .on-mobile .hamburger {
        background: transparent;
        border: none;
        height: 50px;
        position: absolute;
        right: 15px;
        top: 8px;
        display: block; }
        .header .on-mobile .hamburger span {
          display: block;
          margin: 12px 0;
          width: 40px;
          height: 2px;
          background: #013350; } }

.get-in-touch {
  background: #fafafa;
  border-top: 5px solid #dee2e6; }
  @media (max-width: 1024px) {
    .get-in-touch {
      display: none; } }
  .get-in-touch .col-md-12 {
    min-height: 0px; }
  .get-in-touch .customsol {
    position: relative;
    padding: 30px 0px; }
    .get-in-touch .customsol .customsol-content {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center; }
      .get-in-touch .customsol .customsol-content .git-left {
        flex: 3 1; }
        .get-in-touch .customsol .customsol-content .git-left .git-title {
          font-size: 1.5rem;
          font-family: 'Raleway', sans-serif;
          margin-bottom: 10px;
          display: block; }
        .get-in-touch .customsol .customsol-content .git-left p {
          margin-bottom: 0; }
      .get-in-touch .customsol .customsol-content .git-right {
        flex: 1 1;
        padding-left: 40px; }
        .get-in-touch .customsol .customsol-content .git-right .btn-git {
          text-align: center;
          display: block;
          background: #ffffff;
          padding: 10px 20px;
          border-radius: 5px;
          font-weight: 600;
          color: #013350;
          border: 1px solid #013350; }
          .get-in-touch .customsol .customsol-content .git-right .btn-git:hover {
            background: #013350;
            color: #ffffff; }

section.footer {
  padding: 185px 0px 110px;
  background: #013350;
  color: #ffffff;
  position: relative;
  line-height: normal; }
  section.footer a {
    color: #ffffff; }
  section.footer .ct-footer .heading-footer-col {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative; }
    section.footer .ct-footer .heading-footer-col:before {
      position: absolute;
      left: 0px;
      content: '';
      bottom: 0;
      height: 2px;
      width: 30px;
      background: #e67f10;
      margin-bottom: 0px; }
  section.footer .ct-footer .content-footer-col .footer-item-contact {
    padding-left: 25px;
    position: relative;
    margin-bottom: 10px; }
    section.footer .ct-footer .content-footer-col .footer-item-contact .fic-icon {
      position: absolute;
      left: 0px;
      top: 0px; }
      section.footer .ct-footer .content-footer-col .footer-item-contact .fic-icon .fa {
        color: #e67f10; }
  section.footer .ct-footer .logo img {
    width: 230px;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 20px;
    background: #f4f4f4; }
  section.footer ul {
    padding: 0px 0px;
    margin: 0px 0px;
    list-style: none; }
  @media (max-width: 1024px) {
    section.footer {
      padding-top: 100px; }
      section.footer .heading-footer-col {
        margin-top: 40px; } }

.wrap-circle-sosmed {
  display: table;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid #e67f10;
  float: left;
  margin-right: 10px;
  text-align: center; }
  .wrap-circle-sosmed a {
    display: table-cell;
    vertical-align: middle; }

footer {
  text-align: center;
  padding: 10px 0px;
  font-size: 14px; }

@media (max-width: 992px) {
  .carousel {
    margin-top: 0; } }

.carousel::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  content: '';
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.carousel .box-gallery {
  z-index: -1; }
  .carousel .box-gallery img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh !important;
    width: 100vw !important;
    object-fit: cover; }

.carousel img.static {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1; }

.carousel .table-wrapper {
  display: table;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 1; }
  .carousel .table-wrapper .table-cell {
    vertical-align: middle;
    display: table-cell;
    text-align: center; }
    .carousel .table-wrapper .table-cell h2 {
      color: #fafafa;
      font-size: 58px;
      font-weight: 300;
      font-family: "Oswald", sans-serif;
      margin-top: 35px; }
    .carousel .table-wrapper .table-cell p {
      font-size: 20px;
      margin: 0;
      color: #fafafa;
      font-weight: 500;
      text-shadow: 0.5px 0.5px 1px #fafafa; }
    .carousel .table-wrapper .table-cell span {
      font-size: 68px;
      color: #fafafa;
      top: 38px;
      position: relative;
      font-weight: 100;
      cursor: pointer; }
  @media (max-width: 1024px) {
    .carousel .table-wrapper {
      height: 40vh; }
      .carousel .table-wrapper .table-cell h2 {
        font-size: 38px;
        margin-top: 35px; }
      .carousel .table-wrapper .table-cell p {
        font-size: 20px; }
      .carousel .table-wrapper .table-cell span {
        display: none; } }

.about-us {
  padding: 110px 0px;
  overflow: hidden; }
  .about-us .section-title {
    width: 30%;
    float: left;
    margin-bottom: 0px; }
    .about-us .section-title h2 {
      position: relative;
      padding-bottom: 25px;
      margin-bottom: 25px;
      font-weight: 700; }
      .about-us .section-title h2:before {
        position: absolute;
        content: '';
        left: 50%;
        bottom: 0;
        height: 2px;
        width: 40px;
        margin-left: -20px;
        background: #e67f10;
        margin-bottom: 0px; }
  .about-us .si-about {
    font-size: 16px;
    margin: 0 auto;
    width: 70%;
    float: left;
    text-align: left;
    border-left: 1px solid #bfbfbf;
    padding-left: 40px; }
    @media (max-width: 768px) {
      .about-us .si-about {
        border: none;
        max-width: calc(100% - 30px);
        float: none;
        padding: 0 15px;
        text-align: center; } }

.services {
  border-top: 0px solid #e6e6e6;
  position: relative;
  width: 100%;
  background: #013350; }
  .services .no-padding {
    padding: 0; }
  .services .cm-left {
    width: 40%;
    float: left;
    height: 100%;
    padding: 0px;
    position: relative; }
    @media (max-width: 1200px) {
      .services .cm-left {
        display: none; } }
    .services .cm-left .cm-left-content-layer {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: relative;
      padding: 160px 130px;
      color: #ffffff; }
      .services .cm-left .cm-left-content-layer .cm-left-content {
        position: relative;
        z-index: 2; }
      .services .cm-left .cm-left-content-layer .section-title {
        color: #ffffff;
        background: #e67f10;
        padding: 50px; }
        .services .cm-left .cm-left-content-layer .section-title h2 {
          font-family: "Open Sans", sans-serif;
          font-weight: 400; }
          .services .cm-left .cm-left-content-layer .section-title h2:before {
            background: #ffffff; }
    .services .cm-left .bg-cm {
      height: 100%;
      background-position: center center;
      position: absolute;
      width: 100%;
      top: 0px;
      left: 0px;
      background-image: url(/static/media/bg-image2.0b6c6cfc.jpg); }
  .services .cm-right {
    width: 60%;
    float: left;
    padding: 110px 100px;
    min-height: 600px;
    background: #013350;
    color: #ffffff; }
    @media (max-width: 1200px) {
      .services .cm-right {
        width: 100%;
        float: none;
        padding: 100px 20px; } }
    .services .cm-right .section-title h1::before {
      background: #ffffff; }
    .services .cm-right .why-list {
      float: left;
      width: 100%; }
      .services .cm-right .why-list .wl-box {
        width: 50%;
        position: relative;
        float: left;
        margin-top: 30px; }
        @media (max-width: 800px) {
          .services .cm-right .why-list .wl-box {
            width: 100%;
            float: none;
            margin-bottom: 40px; }
            .services .cm-right .why-list .wl-box:last-child {
              margin-bottom: 80px; } }
        .services .cm-right .why-list .wl-box .wl-box-left {
          font-size: 50px;
          width: 50px;
          position: absolute;
          left: 0px;
          top: 0px;
          color: #e67f10; }
        .services .cm-right .why-list .wl-box .wl-box-right {
          padding-left: 80px;
          text-align: left;
          padding-right: 30px; }
          .services .cm-right .why-list .wl-box .wl-box-right h5 {
            font-family: "Oswald", sans-serif; }

.testimonials-section {
  padding: 110px 0px 185px;
  background: #013350;
  color: #ffffff;
  position: relative;
  overflow: hidden; }
  .testimonials-section .testimonials-wrap {
    position: relative;
    z-index: 1; }
    .testimonials-section .testimonials-wrap .item-testimonial {
      position: relative;
      width: 100%;
      height: 180px; }
      .testimonials-section .testimonials-wrap .item-testimonial .client-info {
        width: 120px;
        text-align: center;
        position: absolute; }
        .testimonials-section .testimonials-wrap .item-testimonial .client-info .client-img {
          height: 100px;
          width: 100px;
          border-radius: 100%;
          overflow: hidden;
          margin: 0 auto 10px; }
          .testimonials-section .testimonials-wrap .item-testimonial .client-info .client-img img {
            width: 100%; }
        .testimonials-section .testimonials-wrap .item-testimonial .client-info .rating {
          color: #e67f10; }
      .testimonials-section .testimonials-wrap .item-testimonial .client-content {
        padding: 0px 30px 0px 150px; }
        .testimonials-section .testimonials-wrap .item-testimonial .client-content p {
          font-style: italic; }
        .testimonials-section .testimonials-wrap .item-testimonial .client-content h4 {
          color: #e67f10; }
        .testimonials-section .testimonials-wrap .item-testimonial .client-content h5 {
          font-weight: 300;
          font-size: 16px; }
  .testimonials-section .bg-tst {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0px;
    left: 0px; }
    .testimonials-section .bg-tst .bg-color-tst {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      background: #013350;
      opacity: 0.9; }
    .testimonials-section .bg-tst .bg-img-tst {
      background: url(/static/media/bg-image2.0b6c6cfc.jpg);
      background-size: 100% auto;
      z-index: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%; }
  @media (max-width: 1024px) {
    .testimonials-section {
      padding: 100px 0; }
      .testimonials-section .slick-arrow {
        display: none !important; }
      .testimonials-section .testimonials-wrap .item-testimonial .client-info {
        width: 80px; }
        .testimonials-section .testimonials-wrap .item-testimonial .client-info .client-img {
          height: 70px;
          width: 70px; }
          .testimonials-section .testimonials-wrap .item-testimonial .client-info .client-img img {
            width: 100%; }
      .testimonials-section .testimonials-wrap .item-testimonial .client-content {
        padding: 0px 0 0px 95px; } }

.secondary-banner-wrapper {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  height: 500px;
  position: relative;
  overflow: hidden; }
  .secondary-banner-wrapper::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '';
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0, 51, 82, 0.76) 0%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
    /* IE6-9 */ }
  .secondary-banner-wrapper .rera-number-wrapper {
    margin-top: 8px;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    display: inline-block;
    background-color: rgba(230, 126, 18, 0.9); }
  .secondary-banner-wrapper .intro {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 1; }
    .secondary-banner-wrapper .intro h1, .secondary-banner-wrapper .intro h2 {
      margin: 70px 0 0 0;
      color: #fafafa;
      font-size: 58px;
      font-weight: 300; }
  @media (max-width: 1024px) {
    .secondary-banner-wrapper {
      height: 300px; } }

@media (max-width: 1024px) {
  .about-page .none-sm {
    display: none; } }

.about-page .wrap-content-page {
  padding: 110px 0px; }
  .about-page .wrap-content-page img {
    max-width: 100%; }

.about-page h4.title {
  font-weight: 600; }

.about-page p {
  text-align: justify; }

.about-page ul {
  padding: 0px 0px 0px 17px; }

@media (max-width: 1024px) {
  .about-page .wrap-content-page {
    padding: 50px 0px; } }

.team-section {
  padding: 0;
  padding-bottom: 110px;
  position: relative; }

.team-section .profile {
  margin-top: 25px; }
  .team-section .profile h1 {
    font-weight: normal;
    font-size: 20px;
    margin: 10px 0 0 0; }
  .team-section .profile h2 {
    font-size: 14px;
    font-weight: lighter;
    margin-top: 5px; }
  .team-section .profile .img-box {
    opacity: 1;
    display: block;
    position: relative; }
    .team-section .profile .img-box:after {
      content: "";
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.75);
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0; }

.team-section .img-box ul {
  position: absolute;
  z-index: 2;
  bottom: 50px;
  text-align: center;
  width: 100%;
  padding-left: 0px;
  height: 0px;
  margin: 0px;
  opacity: 0; }

.team-section .profile .img-box:after {
  transition: all 0.5s ease-in-out 0s; }

.team-section .img-box ul {
  transition: all 0.5s ease-in-out 0s; }
  .team-section .img-box ul li {
    transition: all 0.5s ease-in-out 0s; }
  .team-section .img-box ul i {
    font-size: 20px; }
  .team-section .img-box ul li {
    width: 30px;
    height: 30px;
    text-align: center;
    border: 1px solid #ff9c1b;
    margin: 2px;
    padding: 2px;
    display: inline-block; }

.team-section .img-box a {
  color: #fff; }

.team-section .img-box:hover:after, .team-section .img-box:hover ul {
  opacity: 1; }

.team-section .img-box ul a {
  transition: all 0.3s ease-in-out 0s; }

.team-section .img-box a:hover li {
  border-color: #fff;
  color: #ff9c1b; }

.team-section a {
  color: #ff9c1b; }

.team-section img.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.contact-page {
  padding: 110px 0px; }
  .contact-page .contact-form .contact-desc-title {
    margin-bottom: 40px;
    padding-bottom: 40px;
    text-align: center; }
    .contact-page .contact-form .contact-desc-title .heading-contact-title {
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 20px;
      position: relative; }
  .contact-page .contact-form .map-wrapper iframe {
    width: 100%; }
  .contact-page .contact-form .heading-footer-col {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative; }
    @media (max-width: 992px) {
      .contact-page .contact-form .heading-footer-col {
        margin-top: 60px; } }
    .contact-page .contact-form .heading-footer-col:before {
      position: absolute;
      left: 0px;
      content: '';
      bottom: 0;
      height: 2px;
      width: 30px;
      background: #ff9c1b;
      margin-bottom: 0px; }

.general-form-field {
  display: inline-block;
  width: 100%; }
  .general-form-field input[type="text"], .general-form-field input[type="email"], .general-form-field input[type="number"] {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #f0f0f0;
    margin-bottom: 20px;
    padding: 14px 12px;
    width: 100%;
    color: #999999; }
  .general-form-field input.theme-btn[type="submit"] {
    border: none;
    background-color: #013350;
    cursor: pointer;
    margin: 20px 0px;
    height: 50px;
    min-width: 200px; }
    .general-form-field input.theme-btn[type="submit"]:hover {
      background-color: #e67f10; }
  .general-form-field input:focus {
    outline: none;
    box-shadow: none; }
  .general-form-field textarea {
    border: 1px solid #f0f0f0;
    width: 100%;
    height: 180px;
    padding: 14px 12px;
    color: #999999; }
    .general-form-field textarea:focus {
      outline: none;
      box-shadow: none; }

.btn-enquiry {
  padding: 15px 40px;
  border-radius: 100px;
  font-weight: 600;
  margin: 35px 20px 0 0;
  color: #013350;
  border: 1px solid #e67f10;
  background-color: #e67f10;
  cursor: pointer;
  color: #ffffff; }
  .btn-enquiry:hover {
    border-color: #013350;
    background: #013350;
    color: #ffffff; }

.overview {
  padding: 80px 0px; }
  @media (max-width: 769px) {
    .overview .col-md-12.d-flex.align-items-center {
      display: block !important; } }
  .overview .section-title {
    width: 100%; }
    @media (min-width: 769px) {
      .overview .section-title {
        width: 30%;
        float: left;
        margin-bottom: 0px; } }
    .overview .section-title h2 {
      position: relative;
      padding-bottom: 25px;
      margin-bottom: 25px;
      font-weight: 400; }
      @media (min-width: 769px) {
        .overview .section-title h2:before {
          position: absolute;
          content: '';
          left: 50%;
          bottom: 0;
          height: 2px;
          width: 40px;
          margin-left: -20px;
          background: #e67f10;
          margin-bottom: 0px; } }
  .overview .description {
    margin: 0 auto; }
    @media (min-width: 769px) {
      .overview .description {
        width: 70%;
        float: left;
        text-align: left;
        border-left: 1px solid #e6e6e6;
        padding-left: 40px; } }
    @media (max-width: 768px) {
      .overview .description .text-left-sm {
        padding: 0 15px;
        text-align: left; } }
    .overview .description .builder {
      text-align: left;
      margin-top: 12px;
      display: block; }
      @media (max-width: 769px) {
        .overview .description .builder {
          padding: 0 15px; } }
      .overview .description .builder h4 {
        margin-top: 35px;
        margin-bottom: 15px; }
      .overview .description .builder p {
        margin: 5px 0; }
    .overview .description .btn-download {
      background: #ffffff;
      padding: 15px 40px;
      border-radius: 100px;
      font-weight: 600;
      margin: 35px 20px 0 0;
      color: #013350;
      border: 1px solid #013350;
      cursor: pointer; }
      .overview .description .btn-download:hover {
        background: #013350;
        color: #ffffff; }
    @media (max-width: 680px) {
      .overview .description {
        margin: 15px; } }
  .overview .bank-node-wrapper {
    margin-top: 40px; }
    .overview .bank-node-wrapper .wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 15px -15px 0 -15px; }
      .overview .bank-node-wrapper .wrapper .node {
        max-width: 200px;
        max-height: 170px;
        padding: 20px;
        margin: 15px;
        background-color: #ffffff;
        color: #e67f10;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 3px #e9e9e9;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .overview .bank-node-wrapper .wrapper .node img {
          width: 100%;
          object-fit: cover;
          display: block; }
      @media (max-width: 680px) {
        .overview .bank-node-wrapper .wrapper {
          margin-bottom: 40px; }
          .overview .bank-node-wrapper .wrapper .node {
            max-width: 100%;
            max-height: 100%;
            flex-grow: 1; } }

.bg-amenities {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  position: relative; }
  .bg-amenities::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '';
    background-color: rgba(243, 243, 243, 0.85); }

.amenities {
  padding: 50px 15px;
  position: relative;
  z-index: 1; }
  @media (min-width: 768px) {
    .amenities {
      padding: 100px; } }
  .amenities ul.amenity-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .amenities ul.amenity-list li.amenity-item {
      border-radius: 5px;
      background-color: white;
      padding: 20px;
      min-width: 220px;
      max-width: 220px;
      color: #013350;
      text-align: center;
      margin: 15px;
      align-items: center;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 16px;
      transition: box-shadow .5s; }
      .amenities ul.amenity-list li.amenity-item img {
        transition: -webkit-transform .5s;
        transition: transform .5s;
        transition: transform .5s, -webkit-transform .5s;
        width: 30px;
        display: block;
        margin: 10px auto 15px auto; }
      .amenities ul.amenity-list li.amenity-item:hover {
        box-shadow: 0px 0px 10px 3px #dee2e6; }
        .amenities ul.amenity-list li.amenity-item:hover img {
          -webkit-transform: scale(1.5);
                  transform: scale(1.5); }

.location {
  padding: 0;
  display: flex;
  align-items: center;
  padding: 80px 15px; }
  .location .map-wrapper {
    position: relative;
    height: 600px;
    width: 70%;
    flex: 2 1; }
    .location .map-wrapper iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
  .location .address-wrapper {
    flex: 1 1;
    padding-left: 25px; }
    .location .address-wrapper .address {
      text-align: center; }
  @media (max-width: 768px) {
    .location {
      flex-direction: column; }
      .location .map-wrapper {
        width: 100%;
        margin-bottom: 50px; } }

.walk-through-container {
  padding-top: 80px; }
  .walk-through-container .react-photo-gallery--gallery {
    margin: 0 -15px; }
  .walk-through-container .image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .walk-through-container .image-gallery img {
      width: calc((100% / 4) - 30px) !important;
      height: 150px !important;
      margin: 15px !important;
      transition: box-shadow .5s, border-radius .5s, -webkit-transform .5s;
      transition: box-shadow .5s, border-radius .5s, transform .5s;
      transition: box-shadow .5s, border-radius .5s, transform .5s, -webkit-transform .5s;
      object-fit: cover; }
      .walk-through-container .image-gallery img:hover {
        border-radius: 5px;
        box-shadow: 0px 0px 10px 3px #cecece;
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }
      @media (max-width: 992px) {
        .walk-through-container .image-gallery img {
          width: calc((100% / 3) - 4px) !important; } }
      @media (max-width: 768px) {
        .walk-through-container .image-gallery img {
          width: calc((100% / 2) - 4px) !important; } }
      @media (max-width: 580px) {
        .walk-through-container .image-gallery img {
          width: 100% !important;
          margin: 5px 0 !important; } }
  .walk-through-container .youtube-link {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin-top: 60px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 3px #cecece; }
    .walk-through-container .youtube-link iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.floor-node {
  position: relative;
  margin-bottom: 30px;
  display: block;
  background-color: #003352;
  cursor: pointer;
  box-shadow: 0px 0px 10px 3px #e9e9e9;
  border-radius: 5px; }
  .floor-node img {
    border-radius: 5px;
    width: 160px;
    height: 130px;
    object-fit: cover;
    display: block;
    margin: auto; }
    @media (max-width: 768px) {
      .floor-node img {
        height: 200px;
        width: 100%; } }
  .floor-node .react-photo-gallery--gallery {
    overflow: hidden; }
    .floor-node .react-photo-gallery--gallery img {
      display: none;
      margin: auto !important; }
      .floor-node .react-photo-gallery--gallery img:first-child {
        display: block; }
  @media (min-width: 769px) {
    .floor-node .quick-details {
      margin-left: -30px; } }
  .floor-node .quick-details ul.list {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .floor-node .quick-details ul.list li.item {
      font-weight: 600;
      padding: 6px 20px;
      color: white !important;
      line-height: 20px; }
      .floor-node .quick-details ul.list li.item.bhk {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        background: #e67f10;
        padding: 15px;
        display: inline-block;
        width: calc(100% - 15px);
        margin-top: -15px;
        border-radius: 5px; }
      .floor-node .quick-details ul.list li.item span {
        display: block;
        font-weight: 600;
        font-size: 10px; }
  @media (max-width: 768px) {
    .floor-node .quick-details {
      padding: 10px; }
      .floor-node .quick-details ul.list li.item {
        font-weight: 600;
        padding: 6px 20px;
        color: white !important;
        line-height: 20px; }
        .floor-node .quick-details ul.list li.item.bhk {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
          background: #e67f10;
          padding: 15px;
          display: block;
          width: 100%;
          margin-top: -40px; }
        .floor-node .quick-details ul.list li.item span {
          display: block;
          font-weight: 600;
          font-size: 10px; } }

.floor-plan-wrapper {
  padding-top: 80px;
  padding-bottom: 80px; }

.bg-near-by-places {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  position: relative; }
  .bg-near-by-places::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '';
    background-color: rgba(243, 243, 243, 0.85); }

.near-by-places {
  padding: 60px 0;
  position: relative;
  z-index: 1; }
  .near-by-places ul.place-list {
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center; }
    .near-by-places ul.place-list .place-item {
      border-radius: 5px;
      padding: 20px;
      width: 100%;
      height: 280px;
      text-align: center;
      margin: 15px 0;
      align-items: center;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 16px;
      transition: box-shadow .5s;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: stretch; }
      .near-by-places ul.place-list .place-item img {
        transition: -webkit-transform .5s;
        transition: transform .5s;
        transition: transform .5s, -webkit-transform .5s;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover; }
      .near-by-places ul.place-list .place-item .content {
        border-radius: 5px;
        opacity: 0;
        text-align: center;
        position: relative;
        z-index: 1;
        padding: 20px;
        color: #013350;
        background-color: white;
        transition: all .5s;
        display: flex;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        align-items: stretch; }
        .near-by-places ul.place-list .place-item .content h2 {
          font-size: 20px;
          margin-bottom: 10px; }
        .near-by-places ul.place-list .place-item .content p {
          margin: 2px; }
      .near-by-places ul.place-list .place-item:hover {
        transition: all .5s;
        box-shadow: 0px 0px 10px 3px #dee2e6; }
        .near-by-places ul.place-list .place-item:hover img {
          -webkit-transform: scale(1.5);
                  transform: scale(1.5); }
        .near-by-places ul.place-list .place-item:hover .content {
          opacity: 1; }

.slider-banner {
  overflow: hidden;
  position: relative;
  max-height: 490px; }
  .slider-banner::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '';
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0, 51, 82, 0.76) 0%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
    /* IE6-9 */ }
  .slider-banner img {
    min-height: 500px;
    max-height: 500px; }
  .slider-banner .rera-number-wrapper {
    margin-top: 8px;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    display: inline-block;
    background-color: rgba(230, 126, 18, 0.9); }
  .slider-banner .intro {
    display: flex;
    align-items: center;
    height: 490px;
    position: relative;
    z-index: 1;
    margin-top: -520px; }
    .slider-banner .intro h1, .slider-banner .intro h2 {
      margin: 70px 0 0 0;
      color: #fafafa;
      font-size: 58px;
      font-weight: 300; }
  @media (max-width: 580px) {
    .slider-banner {
      max-height: 300px; } }

.Specifications {
  padding-top: 80px;
  padding-bottom: 80px;
  box-shadow: 0px 0px 8px 8px #f1f0f0;
  margin: 50px auto 30px auto; }
  .Specifications .nav-pills {
    align-items: stretch;
    justify-content: center; }
  .Specifications div.content-wrapper {
    padding: 15px 0; }
  .Specifications div.content-wrapper *:last-child {
    margin-bottom: 0; }
  .Specifications li, .Specifications p {
    margin-bottom: 15px; }
  .Specifications .nav-item {
    display: flex;
    width: 220px;
    margin: 10px; }
    .Specifications .nav-item .nav-link {
      font-weight: 600;
      color: #003352;
      border-bottom: 5px solid #013350;
      background: transparent;
      width: 100%;
      display: block;
      border-radius: 0;
      background-color: transparent;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left; }
      .Specifications .nav-item .nav-link span {
        background-image: url(/static/media/012-general-info.9e547f5e.svg);
        width: 30px;
        height: 30px;
        background-size: 100% 100%;
        margin-right: 15px; }
        .Specifications .nav-item .nav-link span.internal-wall-finishes {
          background-image: url(/static/media/003-indoor-walls.6045e785.svg); }
        .Specifications .nav-item .nav-link span.flooring {
          background-image: url(/static/media/001-indoor-flooring.959badad.svg); }
        .Specifications .nav-item .nav-link span.ceilings {
          background-image: url(/static/media/010-ceiling.997bee34.svg); }
        .Specifications .nav-item .nav-link span.external-wall-finishes {
          background-image: url(/static/media/002-outdoor-walls.4e1829b7.svg); }
        .Specifications .nav-item .nav-link span.external-floor-finishes {
          background-image: url(/static/media/011-outdoor-flooring.b7178377.svg); }
      .Specifications .nav-item .nav-link.active {
        background-color: #fafafa;
        border-color: #e67f10; }
  .Specifications .tab-content {
    border: 1px solid #e6e6e6;
    padding: 15px;
    margin-top: 23px;
    border-radius: 5px; }

.property-details-section {
  border-top: 1px solid #e9e9e9;
  position: relative; }
  .property-details-section .sticky-nav {
    opacity: 0;
    position: fixed;
    display: flex;
    z-index: 1;
    justify-content: space-around;
    list-style-type: none;
    padding: 0;
    width: 100%;
    top: -10%;
    z-index: 999;
    transition: all .5s linear; }
    .property-details-section .sticky-nav.active {
      top: 0;
      opacity: 1; }
    .property-details-section .sticky-nav li {
      flex-grow: 1;
      border-left: 2px solid #ddd; }
      .property-details-section .sticky-nav li:first-child {
        border: none; }
      .property-details-section .sticky-nav li a {
        transition: all .5s;
        padding: 15px;
        background-color: rgba(3, 45, 72, 0.84);
        color: #ffffff;
        text-align: center;
        display: block;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        border-bottom: 2px solid #fff; }
      .property-details-section .sticky-nav li.active a {
        background: #e67f10 !important; }
    .property-details-section .sticky-nav:hover li a {
      background-color: #032d48; }

