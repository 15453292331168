@import '../../styles/_variables';

.secondary-banner-wrapper {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  height: 500px;
  position: relative;
  overflow: hidden;
  @include overlayBlackHorizontal();

  .rera-number-wrapper {
    margin-top: 8px;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    display: inline-block;
    background-color: rgba(230, 126, 18, 0.9);
  }

  .intro {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 1;

    h1, h2 {
      margin: 70px 0 0 0;
      color: $colorDefault;
      font-size: 58px;
      font-weight: 300;
    }
  }

  @media(max-width: 1024px) {
    height: 300px;
  }
}


